import axios from 'axios'
import { BASE_URL, API_VERSION, STARTINVENTORYBASEURL, PROJECTSBASEURL, ANALYTICSSTARTINVENTORYBASEURL } from '../endpoints'

export const GET_ALL_START_INVENTORY = 'GET_ALL_START_INVENTORY'
export const ADD_START_INVENTORY = 'ADD_START_INVENTORY'
export const EDIT_START_INVENTORY = 'EDIT_START_INVENTORY'
export const DELETE_START_INVENTORY = 'DELETE_START_INVENTORY'
export const GET_ANALYTICS_START_INVENTORY = 'GET_ANALYTICS_START_INVENTORY'

export const getAllStartInventory = (params) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    const qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    return axios.get(BASE_URL + API_VERSION + PROJECTSBASEURL + STARTINVENTORYBASEURL + '?' + qs, options)
        .then((response) => {
            dispatch({ type: GET_ALL_START_INVENTORY, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addStartInventory = (payload) => (dispatch) => {
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + API_VERSION + PROJECTSBASEURL + STARTINVENTORYBASEURL, payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_START_INVENTORY, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteStartInventory = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + API_VERSION + PROJECTSBASEURL + STARTINVENTORYBASEURL + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_START_INVENTORY, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editStartInventory = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.put(BASE_URL + API_VERSION + PROJECTSBASEURL + STARTINVENTORYBASEURL + '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_START_INVENTORY, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const getStartInventoryAnalytics = (params) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    const qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    return axios.get(BASE_URL + API_VERSION + PROJECTSBASEURL + ANALYTICSSTARTINVENTORYBASEURL + '?' + qs, options)
        .then((response) => {
            dispatch({ type: GET_ANALYTICS_START_INVENTORY, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}