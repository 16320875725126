import {
    GET_ALL_INVENTORY_CHECKLISTS,
    ADD_INVENTORY_CHECKLIST,
    EDIT_INVENTORY_CHECKLIST,
    DELETE_INVENTORY_CHECKLIST
} from '../actions/InventoryChecklistActions'

const initialState = {
    data: [],
    allInventoryChecklists: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const InventoryChecklistReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_INVENTORY_CHECKLIST: {
            let tempArray = Object.assign({}, state.allInventoryChecklists)
            tempArray.rows = [action.payload, ...state.allInventoryChecklists.rows]
            return {
                ...state,
                allInventoryChecklists: tempArray,
            }
        }
        case EDIT_INVENTORY_CHECKLIST: {
            let tempArray = Object.assign({}, state.allInventoryChecklists)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allInventoryChecklists: tempArray,
            }
        }
        case DELETE_INVENTORY_CHECKLIST: {
            let tempArray = Object.assign({}, state.allInventoryChecklists)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allInventoryChecklists: tempArray,
            }
        }
        case GET_ALL_INVENTORY_CHECKLISTS: {
            return {
                ...state,
                allInventoryChecklists: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default InventoryChecklistReducer
