import axios from 'axios'
import { BASE_URL, API_VERSION, STORESBASEURL } from '../endpoints'

export const GET_ALL_STORES = 'GET_ALL_STORES'
export const ADD_STORE = 'ADD_STORE'
export const EDIT_STORE = 'EDIT_STORE'
export const DELETE_STORE = 'DELETE_STORE'



export const getAllStores = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL + API_VERSION + STORESBASEURL + '?size=300', options)
        .then((response) => {
            dispatch({ type: GET_ALL_STORES, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addStore = (payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
        method: 'POST'
    };

    options.body = new FormData();
    for (let key in payload) {
        options.body.append(key, payload[key]);
    }

    return fetch(BASE_URL + API_VERSION + STORESBASEURL, options)
        .then(response => { return response.json() })
        .then(responseJson => {
            dispatch({ type: ADD_STORE, payload: responseJson });
            return Promise.resolve()
        }).catch(error => {
            return Promise.reject(error)
        })


    // return axios.post(BASE_URL + API_VERSION + STORESBASEURL, payload, apiHeader)
    //     .then(response => {
    //         dispatch({ type: ADD_STORE, payload: response.data });
    //         return Promise.resolve()
    //     })
    //     .catch((error) => {
    //         return Promise.reject(error.response.data.error)
    //     })
}

export const deleteStore = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + API_VERSION + STORESBASEURL + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_STORE, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editStore = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.put(BASE_URL + API_VERSION + STORESBASEURL + '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_STORE, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}