import { combineReducers } from 'redux'
import ScrumBoardReducer from './ScrumBoardReducer'
import NotificationReducer from './NotificationReducer'
import EcommerceReducer from './EcommerceReducer'
import NavigationReducer from './NavigationReducer'
import UserReducer from './UserReducer'
import ProjectReducer from './ProjectReducer'
import TeamReducer from './TeamReducer'
import RoleReducer from './RoleReducer'
import RegionReducer from './RegionReducer'
import StoreReducer from './StoreReducer'
import AreaReducer from './AreaReducer'
import SalesOffice from './SalesOfficeReducer'
import TerritoryReducer from './TerritoryReducer'

import InventoryChecklistReducer from './InventoryChecklistReducer'
import MerchblitzReducer from './MerchblitzReducer'
import MBRetainerReducer from './MBRetainerReducer'

import StartInventoryReducer from './StartInventoryReducer'
import EndInventoryReducer from './EndInventoryReducer'
import VariantReducer from './VariantReducer'

const RootReducer = combineReducers({
    notifications: NotificationReducer,
    navigations: NavigationReducer,
    scrumboard: ScrumBoardReducer,
    ecommerce: EcommerceReducer,
    user: UserReducer,
    project: ProjectReducer,
    team: TeamReducer,
    role: RoleReducer,
    region: RegionReducer,
    store: StoreReducer,
    area: AreaReducer,
    salesOffice: SalesOffice,
    territory: TerritoryReducer,

    inventoryChecklist: InventoryChecklistReducer,
    merchblitz: MerchblitzReducer,
    mbretainer: MBRetainerReducer,

    startInventory: StartInventoryReducer,
    endInventory: EndInventoryReducer,
    variant: VariantReducer
})

export default RootReducer
