export const API_KEY = 'test'
export const LOCAL_CONFIG = 'environmentVariable'

// export const BASE_URL = 'http://localhost:5000/api/'
export const BASE_URL ='https://api.gvintegrated.com/api/'
// export const BASE_URL ='https://api.gvintegrated.com/api/'
// export const BASE_URL = 'http://merchblitz-prod-api-env.ap-southeast-1.elasticbeanstalk.com/api/'
export const API_VERSION = 'v1'


export const CHECKIN = '/users/checkin'
export const CHECKOUT = '/users/checkout'
export const BREAKSTART = '/users/breakstart'
export const BREAKEND = '/users/breakend'

export const RECORD = '/api/create-record'

export const GETPROJECTS = '/project'
export const GETROLES = '/role/admin'
export const GETREGIONS = '/region'
export const GETTEAMS = '/team'
export const GETUSERLOG = '/userlog'

export const GETUSERS = '/users/get'
export const USERS = '/users'
export const GETUNAPPROVEDUSERS = '/users/get/unapproved'
export const APPROVEUSER = '/approveUser'

export const REGISTER = '/users/register'
export const USERBASEURL = '/users'
export const LOGIN = '/users/login'
export const LOGOUT = '/users/logout'


export const ROLESBASEURL = '/role'
export const TEAMSBASEURL = '/team'
export const REGIONSBASEURL = '/region'
export const PROJECTSBASEURL = '/project'
export const STORESBASEURL = '/store'
export const AREASBASEURL = '/area'
export const USERLOGBASEURL = '/userlog'
export const TERRITORYBASEURL = '/territory'

export const SALESOFFICEBASEURL = '/salesoffice'
export const MERCHBLITZBASEURL = '/merchblitz'
export const ANALYTICSMERCHBLITZBASEURL = '/analytics/merchblitz'
export const MBRETAINERBASEURL = '/mbretainer'
export const ANALYTICSMBRETAINERBASEURL = '/analytics/mbretainer'

export const STARTINVENTORYBASEURL = '/startinventory'
export const ANALYTICSSTARTINVENTORYBASEURL = '/analytics/startinventory'
export const ENDINVENTORYBASEURL = '/endinventory'
export const ANALYTICSENDINVENTORYBASEURL = '/analytics/endinventory'
export const VARIANTBASEURL = '/variant'
export const ANALYTICSUSERACTIVITYBASEURL = '/analytics/useractivity'

export const INVENTORYCHECKLISTBASEURL = '/inventorychecklist'

export const GETALLUSERACTIVITY = '/users/getAllActivity'


