import axios from 'axios'
import { BASE_URL, API_VERSION, ENDINVENTORYBASEURL, PROJECTSBASEURL, ANALYTICSENDINVENTORYBASEURL } from '../endpoints'

export const GET_ALL_END_INVENTORY = 'GET_ALL_END_INVENTORY'
export const ADD_END_INVENTORY = 'ADD_END_INVENTORY'
export const EDIT_END_INVENTORY = 'EDIT_END_INVENTORY'
export const DELETE_END_INVENTORY = 'DELETE_END_INVENTORY'
export const GET_ANALYTICS_END_INVENTORY = 'GET_ANALYTICS_END_INVENTORY'

export const getAllEndInventory = (params) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    const qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    return axios.get(BASE_URL + API_VERSION + PROJECTSBASEURL + ENDINVENTORYBASEURL + '?' + qs, options)
        .then((response) => {
            dispatch({ type: GET_ALL_END_INVENTORY, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addEndInventory = (payload) => (dispatch) => {
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + API_VERSION + PROJECTSBASEURL + ENDINVENTORYBASEURL, payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_END_INVENTORY, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteEndInventory = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + API_VERSION + PROJECTSBASEURL + ENDINVENTORYBASEURL + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_END_INVENTORY, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editEndInventory = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.put(BASE_URL + API_VERSION + PROJECTSBASEURL + ENDINVENTORYBASEURL + '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_END_INVENTORY, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const getEndInventoryAnalytics = (params) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    const qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    return axios.get(BASE_URL + API_VERSION + PROJECTSBASEURL + ANALYTICSENDINVENTORYBASEURL + '?' + qs, options)
        .then((response) => {
            dispatch({ type: GET_ANALYTICS_END_INVENTORY, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}