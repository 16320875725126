import {
    GET_ALL_VARIANTS,
    ADD_VARIANT,
    EDIT_VARIANT,
    DELETE_VARIANT
} from '../actions/VariantAction'

const initialState = {
    data: [],
    allVariants: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const VariantReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_VARIANT: {
            let tempArray = Object.assign({}, state.allVariants)
            tempArray.rows = [action.payload, ...state.allVariants.rows]
            return {
                ...state,
                allVariants: tempArray,
            }
        }
        case EDIT_VARIANT: {
            let tempArray = Object.assign({}, state.allVariants)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allVariants: tempArray,
            }
        }
        case DELETE_VARIANT: {
            let tempArray = Object.assign({}, state.allVariants)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allVariants: tempArray,
            }
        }
        case GET_ALL_VARIANTS: {
            return {
                ...state,
                allVariants: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default VariantReducer
