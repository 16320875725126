import axios from 'axios'
import {BASE_URL, API_VERSION, TERRITORYBASEURL} from '../endpoints'

export const GET_ALL_TERRITORY = 'GET_ALL_TERRITORY'
export const ADD_TERRITORY = 'ADD_TERRITORY'
export const EDIT_TERRITORY = 'EDIT_TERRITORY'
export const DELETE_TERRITORY = 'DELETE_TERRITORY'

export const getTerritories = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL + API_VERSION + TERRITORYBASEURL + '?size=300', options)
        .then((response) => {
            dispatch({ type: GET_ALL_TERRITORY, payload: response.data})
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addTerritory = (payload) => (dispatch) => {
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + API_VERSION + TERRITORYBASEURL, payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_TERRITORY, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteTerritory = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + API_VERSION + TERRITORYBASEURL + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_TERRITORY, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editTerritory = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.put(BASE_URL + API_VERSION + TERRITORYBASEURL + '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_TERRITORY, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}