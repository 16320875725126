import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { BASE_URL, API_VERSION, LOGIN, LOGOUT, GETUSERS } from '../../app/redux/endpoints'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false
    }
    return true


    // const decodedToken = jwtDecode(accessToken)
    // const currentTime = Date.now() / 1000
    // return decodedToken.exp > currentTime
}

const setSession = (accessToken, userId, projectAccess, roleName) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('userId', userId)
        localStorage.setItem('projectAccess', projectAccess)
        localStorage.setItem('roleName', roleName)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('userId')
        localStorage.removeItem('projectAccess', projectAccess)
        localStorage.removeItem('roleName', roleName)
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = (username, password) => {
        return new Promise(async (resolve, reject) => {
            try {
                const response = await axios.post(BASE_URL + API_VERSION + LOGIN + '?appversion=admin', {
                    username,
                    password,
                })

                const { accessToken, userData, projectAccess, roleName } = response.data

                // return console.log()
                if (projectAccess.length === 0) {
                    return reject({
                        error: {
                            message: 'You dont have permission to access this page.',
                            status: 403
                        }
                    })
                }

                setSession(accessToken, userData.id, JSON.stringify(projectAccess), roleName)
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user: userData,
                    },
                })
                resolve()
            } catch (e) {
                console.log(e)
                reject(e)
            }

        })
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null, null, null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                const userId = window.localStorage.getItem('userId')
                const projectAccess = window.localStorage.getItem('projectAccess')
                const roleName = window.localStorage.getItem('roleName')

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken, userId, projectAccess, roleName)
                    let apiHeader = {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    }

                    const response = await axios.get(BASE_URL + API_VERSION + '/users/' + userId, apiHeader)

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user: response.data,
                        },
                    })
                }
                else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
