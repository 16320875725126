import {
    GET_ALL_PROJECTS,
    ADD_PROJECT,
    EDIT_PROJECT,
    DELETE_PROJECT
} from '../actions/ProjectActions'

const initialState = {
    data: [],
    allProjects: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const ProjectReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_PROJECT: {
            let tempArray = Object.assign({}, state.allProjects)
            tempArray.rows = [action.payload, ...state.allProjects.rows]
            return {
                ...state,
                allProjects: tempArray,
            }
        }
        case EDIT_PROJECT: {
            let tempArray = Object.assign({}, state.allProjects)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allProjects: tempArray,
            }
        }
        case DELETE_PROJECT: {
            let tempArray = Object.assign({}, state.allProjects)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allProjects: tempArray,
            }
        }
        case GET_ALL_PROJECTS: {
            return {
                ...state,
                allProjects: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default ProjectReducer
