import {
    GET_ALL_TEAMS,
    ADD_TEAM,
    EDIT_TEAM,
    DELETE_TEAM
} from '../actions/TeamActions'

const initialState = {
    data: [],
    allTeams: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const TeamReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_TEAM: {
            let tempArray = Object.assign({}, state.allTeams)
            tempArray.rows = [action.payload, ...state.allTeams.rows]
            return {
                ...state,
                allTeams: tempArray,
            }
        }
        case EDIT_TEAM: {
            let tempArray = Object.assign({}, state.allTeams)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allTeams: tempArray,
            }
        }
        case DELETE_TEAM: {
            let tempArray = Object.assign({}, state.allTeams)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allTeams: tempArray,
            }
        }
        case GET_ALL_TEAMS: {
            return {
                ...state,
                allTeams: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default TeamReducer
