import axios from 'axios'
import { BASE_URL, API_VERSION, MBRETAINERBASEURL, PROJECTSBASEURL, ANALYTICSMBRETAINERBASEURL } from '../endpoints'

export const GET_ALL_MBRETAINER = 'GET_ALL_MBRETAINER'
export const ADD_MBRETAINER = 'ADD_MBRETAINER'
export const EDIT_MBRETAINER = 'EDIT_MBRETAINER'
export const DELETE_MBRETAINER = 'DELETE_MBRETAINER'
export const GET_ANALYTICS_MBRETAINER = 'GET_ANALYTICS_MBRETAINER'

export const getAllMBRetainer = (params) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    const qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    return axios.get(BASE_URL + API_VERSION + PROJECTSBASEURL + MBRETAINERBASEURL + '?' + qs, options)
        .then((response) => {
            let uniqueArr = [];
            let uniqueObj = {};

            response.data.rows.forEach((obj) => {
                let key = obj.storeName + obj.storeOwnerName + obj.age + obj.storeRepresentative + obj.street + obj.barangay + obj.city + obj.province + obj.mobileNumber;
                if (!uniqueObj[key]) {
                    uniqueArr.push(obj);
                    uniqueObj[key] = true;
                }
            });


            dispatch({ type: GET_ALL_MBRETAINER, payload: { rows: uniqueArr } })
            return Promise.resolve({ rows: uniqueArr })
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addMBRetainer = (payload) => (dispatch) => {
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }
    return axios.post(BASE_URL + API_VERSION + PROJECTSBASEURL + MBRETAINERBASEURL, payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_MBRETAINER, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteMBRetainer = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + API_VERSION + PROJECTSBASEURL + MBRETAINERBASEURL + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_MBRETAINER, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editMBRetainer = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.put(BASE_URL + API_VERSION + PROJECTSBASEURL + MBRETAINERBASEURL + '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_MBRETAINER, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const getMBRetainerAnalytics = (params) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    const qs = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

    return axios.get(BASE_URL + API_VERSION + PROJECTSBASEURL + ANALYTICSMBRETAINERBASEURL + '?' + qs, options)
        .then((response) => {
            dispatch({ type: GET_ANALYTICS_MBRETAINER, payload: response.data })
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}