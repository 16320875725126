import {
    GET_ALL_START_INVENTORY,
    ADD_START_INVENTORY,
    EDIT_START_INVENTORY,
    DELETE_START_INVENTORY,
    GET_ANALYTICS_START_INVENTORY
} from '../actions/StartInventoryActions'

const initialState = {
    data: [],
    allStartInventory: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const StartInventoryReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_START_INVENTORY: {
            let tempArray = Object.assign({}, state.allStartInventory)
            tempArray.rows = [action.payload, ...state.allStartInventory.rows]
            return {
                ...state,
                allStartInventory: tempArray,
            }
        }
        case EDIT_START_INVENTORY: {
            let tempArray = Object.assign({}, state.allStartInventory)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allStartInventory: tempArray,
            }
        }
        case DELETE_START_INVENTORY: {
            let tempArray = Object.assign({}, state.allStartInventory)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allStartInventory: tempArray,
            }
        }
        case GET_ALL_START_INVENTORY: {
            return {
                ...state,
                allStartInventory: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default StartInventoryReducer
