import {
    GET_ALL_REGIONS,
    ADD_REGION,
    EDIT_REGION,
    DELETE_REGION
} from '../actions/RegionActions'

const initialState = {
    data: [],
    allRegions: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const RegionReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_REGION: {
            let tempArray = Object.assign({}, state.allRegions)
            tempArray.rows = [action.payload, ...state.allRegions.rows]
            return {
                ...state,
                allRegions: tempArray,
            }
        }
        case EDIT_REGION: {
            let tempArray = Object.assign({}, state.allRegions)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allRegions: tempArray,
            }
        }
        case DELETE_REGION: {
            let tempArray = Object.assign({}, state.allRegions)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allRegions: tempArray,
            }
        }
        case GET_ALL_REGIONS: {
            return {
                ...state,
                allRegions: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default RegionReducer
