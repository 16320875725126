import {
    GET_ALL_STORES,
    ADD_STORE,
    EDIT_STORE,
    DELETE_STORE
} from '../actions/StoreActions'

const initialState = {
    data: [],
    allStores: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const storeReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_STORE: {
            let tempArray = Object.assign({}, state.allStores)
            console.log(action.payload)
            tempArray.rows = [action.payload, ...state.allStores.rows]
            return {
                ...state,
                allStores: tempArray,
            }
        }
        case EDIT_STORE: {
            let tempArray = Object.assign({}, state.allStores)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allStores: tempArray,
            }
        }
        case DELETE_STORE: {
            let tempArray = Object.assign({}, state.allStores)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allStores: tempArray,
            }
        }
        case GET_ALL_STORES: {
            return {
                ...state,
                allStores: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default storeReducer
