import {
    GET_ALL_END_INVENTORY,
    ADD_END_INVENTORY,
    EDIT_END_INVENTORY,
    DELETE_END_INVENTORY,
    GET_ANALYTICS_END_INVENTORY
} from '../actions/EndInventoryActions'

const initialState = {
    data: [],
    allEndInventory: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const EndInventoryReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_END_INVENTORY: {
            let tempArray = Object.assign({}, state.allEndInventory)
            tempArray.rows = [action.payload, ...state.allEndInventory.rows]
            return {
                ...state,
                allEndInventory: tempArray,
            }
        }
        case EDIT_END_INVENTORY: {
            let tempArray = Object.assign({}, state.allEndInventory)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allEndInventory: tempArray,
            }
        }
        case DELETE_END_INVENTORY: {
            let tempArray = Object.assign({}, state.allEndInventory)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allEndInventory: tempArray,
            }
        }
        case GET_ALL_END_INVENTORY: {
            return {
                ...state,
                allEndInventory: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default EndInventoryReducer
