import {
    GET_ALL_MERCHBLITZ,
    ADD_MERCHBLITZ,
    EDIT_MERCHBLITZ,
    DELETE_MERCHBLITZ
} from '../actions/MerchblitzActions'

const initialState = {
    data: [],
    allMerchblitz: [],
    latestUpdate: '',
    loading: false,
    error: '',
}

const MerchblitzReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_MERCHBLITZ: {
            let tempArray = Object.assign({}, state.allMerchblitz)
            tempArray.rows = [action.payload, ...state.allMerchblitz.rows]
            return {
                ...state,
                allMerchblitz: tempArray,
            }
        }
        case EDIT_MERCHBLITZ: {
            let tempArray = Object.assign({}, state.allMerchblitz)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allMerchblitz: tempArray,
            }
        }
        case DELETE_MERCHBLITZ: {
            let tempArray = Object.assign({}, state.allMerchblitz)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allMerchblitz: tempArray,
            }
        }
        case GET_ALL_MERCHBLITZ: {
            return {
                ...state,
                allMerchblitz: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}

export default MerchblitzReducer
