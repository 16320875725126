export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION'

// const getfilteredNavigations = (navList = [], role) => {
//     return navList.reduce((array, nav) => {
//         if (nav.auth) {
//             if (nav.auth.includes(role)) {
//                 array.push(nav)
//             }
//         } else {
//             if (nav.children) {
//                 nav.children = getfilteredNavigations(nav.children, role)
//                 array.push(nav)
//             } else {
//                 array.push(nav)
//             }
//         }
//         return array
//     }, [])
// }

export const getNavigationByUser = (navigations) => (dispatch) => {
    dispatch({ type: SET_USER_NAVIGATION, tabAccess: navigations })
    return Promise.resolve()
}
