import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Stores = Loadable(lazy(() => import("./Stores")));
const InventoryChecklist = Loadable(lazy(() => import("./InventoryChecklist")));
const Merchblitz = Loadable(lazy(() => import("./Merchblitz")));
const MBRetainer = Loadable(lazy(() => import("./MBRetainer")));
const MerchblitzNew = Loadable(lazy(() => import("./MerchblitzNew")));

const StartInventory = Loadable(lazy(() => import("./StartInventory")));
const EndInventory = Loadable(lazy(() => import("./EndInventory")));

const Variants = Loadable(lazy(() => import("./Variants")));

const projectsRoutes = [
    {
        path: '/project/stores',
        element: <Stores />,
    },
    {
        path: '/project/merchblitz',
        element: <Merchblitz />,
    },
    {
        path: '/project/mbretainer',
        element: <MBRetainer />,
    },
    {
        path: '/project/merchblitznew',
        element: <MerchblitzNew />,
    },
    {
        path: '/project/inventorychecklists',
        element: <InventoryChecklist />,
    },
    {
        path: '/project/startinventory',
        element: <StartInventory />,
    },
    {
        path: '/project/endinventory',
        element: <EndInventory />,
    },
    {
        path: '/project/variants',
        element: <Variants />,
    },
    // {
    //     path: '/project/roles',
    //     element: <Roles />,
    // },
]

export default projectsRoutes
