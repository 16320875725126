import { navigations } from 'app/navigations'
import { SET_USER_NAVIGATION } from '../actions/NavigationAction'

const initialState = [
    {
        merchNav: [
            {
                id: 8,
                name: 'Stores',
                iconText: 'SI',
                path: '/project/stores',
            },
            {
                id: 10,
                name: 'Merchblitz',
                iconText: 'SU',
                path: '/project/merchblitz',
            },
            {
                id: 11,
                name: 'MBRetainer',
                iconText: 'SU',
                path: '/project/mbretainer',
            },
            {
                id: 12,
                name: 'Merchblitz New',
                iconText: 'SU',
                path: '/project/merchblitznew',
            },
            {
                id: 13,
                name: 'Variants',
                iconText: 'SU',
                path: '/project/variants',
            },
        ],
        defaultNav: [
            {
                id: 0,
                name: 'User',
                path: '/dashboard/users',
                iconText: 'S',
            },
            {
                id: 1,
                name: 'User Activity',
                path: '/dashboard/user-activity',
                iconText: 'S',
            },
            {
                id: 2,
                name: 'Roles',
                path: '/dashboard/roles',
                iconText: 'S',
            },
            {
                id: 3,
                name: 'Teams',
                path: '/dashboard/teams',
                iconText: 'S',
            },
            {
                id: 4,
                name: 'Regions',
                path: '/dashboard/regions',
                iconText: 'S',
            },
            {
                id: 5,
                name: 'Projects',
                path: '/dashboard/projects',
                iconText: 'S',
            },
            {
                id: 6,
                name: 'Area',
                path: '/dashboard/areas',
                iconText: 'S',
            },
            {
                id: 7,
                name: 'Sales Office',
                path: '/dashboard/salesoffices',
                iconText: 'S',
            },
            {
                id: 8,
                name: 'User Logs',
                path: '/dashboard/userlogs',
                iconText: 'S',
            },
            {
                id: 9,
                name: 'Territory',
                path: '/dashboard/territory',
                iconText: 'S',
            }
        ],
        wsopsNav: [
            {
                id: 9,
                name: 'Inventory Checklists',
                iconText: 'SU',
                path: '/project/inventorychecklists',
            },
            {
                id: 11,
                name: 'Start Inventory',
                iconText: 'SU',
                path: '/project/startinventory',
            },
            {
                id: 12,
                name: 'End Inventory',
                iconText: 'SU',
                path: '/project/endinventory',
            },
        ],
        userAccess: [],
        projectAccess: []
    },
]

const NavigationReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_USER_NAVIGATION: {
            let userAccess = state[0].defaultNav
            let projectAccess = []
            if (action.tabAccess) {
                userAccess = state[0].defaultNav.filter((item, index) => {
                    return action.tabAccess[index].canView && item
                })
                if (action.tabAccess[8].canView) {
                    projectAccess.push(...state[0].merchNav)
                }
                if (action.tabAccess[9].canView) {
                    projectAccess.push(...state[0].wsopsNav)
                }
            }

            // console.log('JOJOJOJOJ', action.tabAccess[8])

            // return [...action.tabAccess]
            return [{
                merchNav: state[0].merchNav,
                defaultNav: state[0].defaultNav,
                wsopsNav: state[0].wsopsNav,
                userAccess: userAccess,
                projectAccess: projectAccess,
            }]
        }
        default: {
            return [...state]
        }
    }
}

export default NavigationReducer
