import axios from 'axios'
import { BASE_URL, API_VERSION, PROJECTSBASEURL, INVENTORYCHECKLISTBASEURL } from '../endpoints'

export const GET_ALL_INVENTORY_CHECKLISTS = 'GET_ALL_INVENTORY_CHECKLISTS'
export const ADD_INVENTORY_CHECKLIST = 'ADD_INVENTORY_CHECKLIST'
export const EDIT_INVENTORY_CHECKLIST = 'EDIT_INVENTORY_CHECKLIST'
export const DELETE_INVENTORY_CHECKLIST = 'DELETE_INVENTORY_CHECKLIST'

export const getAllInventoryChecklists = () => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let options = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.get(BASE_URL + API_VERSION + PROJECTSBASEURL + INVENTORYCHECKLISTBASEURL, options)
        .then((response) => {
            dispatch({ type: GET_ALL_INVENTORY_CHECKLISTS, payload: response.data })
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}


export const addInventoryChecklist = (payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `Bearer ` + accessToken,
        }
    }
    return axios.post(BASE_URL + API_VERSION + PROJECTSBASEURL + INVENTORYCHECKLISTBASEURL, payload, apiHeader)
        .then(response => {
            dispatch({ type: ADD_INVENTORY_CHECKLIST, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const deleteInventoryChecklist = (id) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.delete(BASE_URL + API_VERSION + PROJECTSBASEURL + INVENTORYCHECKLISTBASEURL + '/' + id, apiHeader)
        .then(response => {
            dispatch({ type: DELETE_INVENTORY_CHECKLIST, payload: id });
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}

export const editInventoryChecklist = (id, payload) => (dispatch) => {
    const accessToken = window.localStorage.getItem('accessToken')
    let apiHeader = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ` + accessToken,
            'Accept': 'application/json',
        },
    };
    return axios.put(BASE_URL + API_VERSION + PROJECTSBASEURL + INVENTORYCHECKLISTBASEURL + '/' + id, payload, apiHeader)
        .then(response => {
            dispatch({ type: EDIT_INVENTORY_CHECKLIST, payload: response.data });
            return Promise.resolve()
        })
        .catch((error) => {
            return Promise.reject(error.response.data.error)
        })
}