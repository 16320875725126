import {
    GET_ALL_USERS,
    GET_ALL_UNAPPROVED_USERS,
    APPROVE_USER,
    ADD_USER,
    DELETE_USER,
    GET_ALL_USER_ACTIVITY,
    GET_USER_ACTIVITY,
    EDIT_USER,
    DELETE_USER_ACTIVITY
} from '../actions/UserActions'

const initialState = {
    allUserActivity: [],
    allUsers: [],
    allUnapprovedUsers: [],
    userActivity: [],
    latestUpdate: '',
    loading: false,
    error: '',
    token: '',
    isLoggedin: false,
}

const UserReducer = function (state = initialState, action) {
    switch (action.type) {
        case ADD_USER: {
            let tempArray = Object.assign({}, state.allUsers)
            tempArray.rows = [action.payload, ...state.allUsers.rows]
            return {
                ...state,
                allUsers: tempArray,
            }
        }
        case GET_ALL_USERS: {
            return {
                ...state,
                allUsers: action.payload,
            }
        }
        case APPROVE_USER: {
            return {
                ...state
            }
        }
        case GET_ALL_UNAPPROVED_USERS: {
            return {
                ...state,
                allUnapprovedUsers: action.payload,
            }
        }
        case GET_ALL_USER_ACTIVITY: {
            return {
                ...state,
                allUserActivity: action.payload,
            }
        }
        case GET_USER_ACTIVITY: {
            return {
                ...state,
                userActivity: action.payload,
            }
        }
        case DELETE_USER_ACTIVITY: {
            let tempArray = Object.assign({}, state.allUserActivity)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allUserActivity: tempArray,
            }
        }
        case EDIT_USER: {
            let tempArray = Object.assign({}, state.allUsers)
            let target = tempArray.rows.findIndex(item => {return item.id == action.payload.id})
            tempArray.rows[target] = action.payload            
            return {
                ...state,
                allUsers: tempArray,
            }
        }
        case DELETE_USER: {
            let tempArray = Object.assign({}, state.allUsers)
            let newArray = tempArray.rows.filter(item => {return item.id !== action.payload})
            tempArray.rows = newArray            
            return {
                ...state,
                allUsers: tempArray,
            }
        }
        default: {
            return {
                ...state,
            }
        }
    }
}


export default UserReducer
